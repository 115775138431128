var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"scrollable":"","title-class":"h3 text-white font-weight-bolder","title":_vm.title,"size":"lg"},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(_vm.statustk === 11)?_c('b-button',{staticClass:"d-flex justify-content-center",attrs:{"variant":"primary"},on:{"click":_vm.changeStatus}},[(_vm.spinnerOn)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" SAVE ")],1):_vm._e(),(_vm.typestatus == 4)?_c('b-button',{staticClass:"d-flex justify-content-center",attrs:{"variant":"danger"},on:{"click":_vm.changeStatus}},[(_vm.spinnerOn)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" GIVE BACK ")],1):_vm._e(),(_vm.typestatus == 5 || _vm.typestatus == 6)?_c('b-button',{staticClass:"d-flex justify-content-center",attrs:{"variant":"success"},on:{"click":_vm.changeStatus}},[(_vm.spinnerOn)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" PROCESS ")],1):_vm._e()]},proxy:true}]),model:{value:(_vm.ownControl),callback:function ($$v) {_vm.ownControl=$$v},expression:"ownControl"}},[_c('div',[_c('b-row',{staticClass:"mb-1 mt-2"},[_c('b-col',[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticClass:"title_lable d-flex justify-content-center"},[_vm._v("CLIENT ")])]},proxy:true}])},[_c('span',{staticClass:"form-control",staticStyle:{"'border-color":"rgba(255, 255, 255, 0.4)'"},style:(_vm.isDarkSkin
                ? 'background: #17171A;opacity: 0.3;color:white'
                : 'background: #efefef')},[_vm._v(_vm._s(_vm.dataNcr.name))])])],1),_c('b-col',[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticClass:"title_label d-flex justify-content-center"},[_vm._v("AMOUNT ")])]},proxy:true}])},[_c('span',{staticClass:"form-control",staticStyle:{"'border-color":"rgba(255, 255, 255, 0.4)'"},style:(_vm.isDarkSkin
                ? 'background: #17171A;opacity: 0.3;color:white'
                : 'background: #efefef')},[_vm._v(" $ "+_vm._s(_vm.dataNcr.amount))])])],1)],1),(_vm.typestatus == 5)?_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Date:","label-class":"font-weight-bolder"}},[(_vm.moduleId == 4)?_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"disabled":true},model:{value:(_vm.dataNcr.date),callback:function ($$v) {_vm.$set(_vm.dataNcr, "date", $$v)},expression:"dataNcr.date"}}):_c('b-form-datepicker',{staticClass:"font-weight-bolder",attrs:{"locale":"en","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"disabled":true,"placeholder":"mm/dd/yyyy"},model:{value:(_vm.dataNcr.date),callback:function ($$v) {_vm.$set(_vm.dataNcr, "date", $$v)},expression:"dataNcr.date"}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Provider","label-class":"font-weight-bolder"}},[_c('v-select',{staticClass:"font-weight-bolder",attrs:{"options":_vm.providers,"label":"text","reduce":function (value) { return value.id; },"disabled":true},model:{value:(_vm.dataNcr.plataform_id),callback:function ($$v) {_vm.$set(_vm.dataNcr, "plataform_id", $$v)},expression:"dataNcr.plataform_id"}})],1)],1),_c('b-col',{attrs:{"sm":"4"}}),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Equifax","label-class":"font-weight-bolder"}},[_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"disabled":true},model:{value:(_vm.dataNcr.equifax),callback:function ($$v) {_vm.$set(_vm.dataNcr, "equifax", $$v)},expression:"dataNcr.equifax"}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Experian","label-class":"font-weight-bolder"}},[_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"disabled":true},model:{value:(_vm.dataNcr.experian),callback:function ($$v) {_vm.$set(_vm.dataNcr, "experian", $$v)},expression:"dataNcr.experian"}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Trans Union","label-class":"font-weight-bolder"}},[_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"disabled":true},model:{value:(_vm.dataNcr.transunion),callback:function ($$v) {_vm.$set(_vm.dataNcr, "transunion", $$v)},expression:"dataNcr.transunion"}})],1)],1)],1):_vm._e(),_c('validation-observer',[_c('div',{staticClass:"col-lg-12 mt-1"},[_c('ValidationProvider',{attrs:{"name":"comment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('span',[_vm._v("Observation")]),_c('b-textarea',{staticClass:"input-form",class:{ 'border-danger': errors[0] },staticStyle:{"height":"140px"},model:{value:(_vm.obs),callback:function ($$v) {_vm.obs=$$v},expression:"obs"}})],1)]}}])})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }