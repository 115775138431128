import { title } from "@core/utils/filter";

const NcrProgramsFields = [
  {
    key: "client_name",
    label: "Name",
    formatter: title,
    visible: true,
  },
  {
    key: "account",
    label: "Account",
    visible: true,
  },
  {
    key: "risky",
    label: "risky",
    visible: true,
  },

  // {
  //   key: "status_account",
  //   label: "Client Status",
  //   thClass: "text-center",
  //   tdClass: "pt-1",
  //   visible: true,
  // },
  {
    key: "nameadvisor",
    label: "Advisor",
    visible: true,
  },
  {
    key: "created_at",
    label: "Creation Date",
    sortable: true,
    visible: true,
  },
  {
    key: "amount",
    label: "Amount",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    sortable: true,
    visible: true,
  },
  // Hidden for DebtSolution
  {
    key: "card_id",
    label: "CC",
    thClass: "text-center",
    tdClass: "pt-0 text-center",
    visible: true,
  },
  {
    key: "tracking",
    visible: true,
    tdClass: "pt-0",
  },
  {
    key: "actions",
    visible: true,
  },
];

export default {
  NcrProgramsFields,
};
