import { render, staticRenderFns } from "./NcrProgramsTable.vue?vue&type=template&id=465b59ed&scoped=true"
import script from "./NcrProgramsTable.vue?vue&type=script&lang=js"
export * from "./NcrProgramsTable.vue?vue&type=script&lang=js"
import style0 from "./NcrProgramsTable.vue?vue&type=style&index=0&id=465b59ed&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465b59ed",
  null
  
)

export default component.exports