<template>
  <div>
    <b-card no-body class="mb-0 border-0 p-0">
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refNcrProgramstTable'].refresh()"
      >
        <template #custom-vselect>
          <FilterStatusAccount :filters="filter[5]" />
        </template>
        <b-table
          slot="table"
          ref="refNcrProgramstTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          sort-icon-left
          show-empty
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :fields="filteredFields"
          :items="myProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column: NAME -->
          <template #cell(client_name)="data">
            <div>
              <p class="m-0">
                <router-link
                  v-if="moduleId !== 22"
                  class
                  :to="{
                    name: pathDashboard,
                    params: {
                      id: data.item.id_account,
                      idClient: data.item.id_account,
                    },
                  }"
                  target="_blank"
                  >{{ data.item.client_name }}</router-link
                >
                <router-link
                  v-else-if="
                    moduleId === 22 &&
                    (currentUser.role_id === 1 ||
                      currentUser.role_id === 17 ||
                      currentUser.role_id === 2 ||
                      currentUser.role_id === 14)
                  "
                  class
                  :to="{
                    name: pathDashboard,
                    params: {
                      id: data.item.id_account,
                      idClient: data.item.id_account,
                    },
                  }"
                  >{{ data.item.client_name }}</router-link
                >
                <span v-else>{{ data.item.client_name }}</span>
              </p>
            </div>
            <status-account :account="data.item" :text="true"></status-account>
            <!-- <icon-status
              :status-account="data.item.status_account"
              :statusGlobal="data.item"
            /> -->
          </template>
          <template #cell(nameadvisor)="data">
            <span v-if="$route.meta.is_digital == 2">{{
              data.item.nameuser
            }}</span>
            <span v-else>{{ data.value }}</span>
          </template>
          <template #cell(risky)="data">
            <badge-risky-clients
              :id-account="data.item.id_account"
              :risk-level="data.item.risk_level"
              :risk-description="data.item.risk_description"
              :risk-type="data.item.risk_type"
            />
          </template>
          <!-- Column: client_name -->
          <template #cell(created_at)="data">
            <small>{{ data.item.created_at | myGlobalDay }}</small>
          </template>
          <!-- Column: AMOUNT -->
          <template #cell(amount)="data">
            <div>
              <p>
                $ {{ data.item.amount || Number(data.item.amount).toFixed(2) }}
                <feather-icon
                  v-if="data.item.state_charge == 1"
                  icon="CheckCircleIcon"
                  size="15"
                  class="mr-50 text-success"
                />
              </p>
            </div>
          </template>
          <!-- Column: CC Hidden for DebtSolution-->

          <template #cell(card_id)="data">
            <div v-if="data.item.card_id && moduleId != 5">
              <b-button variant="outline" @click="modalCreditCard(data.item)">
                <feather-icon
                  icon="CreditCardIcon"
                  size="15"
                  :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                  class="mr-50"
                />
              </b-button>
            </div>
          </template>
          <!-- Column: Tracking -->
          <template #cell(tracking)="data">
            <div>
              <b-button variant="outline" @click="openModalTracking(data.item)">
                <feather-icon
                  icon="ListIcon"
                  size="15"
                  :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                  class="mr-50"
                />
              </b-button>
            </div>
          </template>
          <!-- Column: Action adapt other modules -->
          <template
            v-if="currentUser.role_id == 1 || currentUser.role_id == 2"
            #custom-foot
          >
            <b-th colspan="5" />
            <b-th colspan="1">
              <span class="d-flex justify-content-end"> Total:</span>
            </b-th>
            <td class="bg-secondary">
              <span class="paddingL text-white"> $ {{ total }}</span>
            </td>
            <b-th colspan="4" />
          </template>
          <template #cell(actions)="data">
            <template
              v-if="
                (data.item.status_id == 5 && $route.meta.is_digital === 1) ||
                ($route.meta.moduleId != 20 && $route.meta.moduleId != 22)
              "
            >
              <div class="d-flex align-items-center">
                <feather-icon
                  class="ml-1"
                  :class="[
                    data.item.state_charge == 0
                      ? 'text-warning clickable'
                      : 'text-success',
                  ]"
                  icon="DollarSignIcon"
                  size="16"
                  @click="
                    data.item.state_charge == 0 ? paymentCharge(data.item) : ''
                  "
                />
              </div>
            </template>

            <template
              v-if="
                (data.item.status_id == 5 && $route.meta.is_digital === 2) ||
                $route.meta.moduleId == 20 ||
                $route.meta.moduleId == 22
              "
            >
              <div
                class="d-flex align-items-center"
                v-if="data.item.status_id != 4"
              >
                <feather-icon
                  class="ml-1"
                  :class="[
                    data.item.state_charge == 0
                      ? 'text-warning clickable'
                      : 'text-success',
                  ]"
                  icon="DollarSignIcon"
                  size="16"
                  @click="
                    data.item.state_charge == 0 ? paymentCharge(data.item) : ''
                  "
                />
              </div>
            </template>

            <template>
              <div class="d-flex align-items-center">
                <feather-icon
                  v-if="
                    data.item.status_id == 2 &&
                    (currentUser.role_id == 2 || currentUser.role_id == 1)
                  "
                  class="ml-1 cursor-pointer"
                  icon="CheckIcon"
                  title="Approve"
                  size="16"
                  @click="sentorevision(data.item.nc_id, 2, 3)"
                />

                <feather-icon
                  v-if="
                    data.item.status_id == 2 &&
                    (currentUser.role_id == 2 || currentUser.role_id == 1)
                  "
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  title="Desapprove"
                  size="16"
                  @click="sentorevision(data.item.nc_id, 3, 1)"
                />

                <feather-icon
                  v-if="
                    data.item.status_id == 1 &&
                    (currentUser.role_id == 3 ||
                      currentUser.role_id == 2 ||
                      currentUser.role_id == 1)
                  "
                  class="ml-1 cursor-pointer"
                  icon="SendIcon"
                  title="Send"
                  size="16"
                  @click="sentorevision(data.item.nc_id, 1, 2)"
                />

                <feather-icon
                  v-if="
                    data.item.status_id == 4 &&
                    (currentUser.role_id == 3 ||
                      currentUser.role_id == 2 ||
                      currentUser.role_id == 1)
                  "
                  class="ml-1 cursor-pointer"
                  icon="SendIcon"
                  title="Send"
                  size="16"
                  @click="sentorevision(data.item.nc_id, 11, 2)"
                />

                <feather-icon
                  v-if="data.item.status_id == 7 && data.item.state_cp == 0"
                  class="ml-1 cursor-pointer"
                  icon="CornerDownLeftIcon"
                  title="Reply"
                  size="16"
                  @click="sentorevision(data.item.nc_id, 4, 7)"
                />

                <feather-icon
                  v-if="
                    data.item.status_id == 1 &&
                    (currentUser.role_id == 3 ||
                      currentUser.role_id == 2 ||
                      currentUser.role_id == 1)
                  "
                  class="ml-1"
                  icon="XIcon"
                  size="16"
                  @click="sentorevision(data.item.nc_id, 6, 10)"
                />
              </div>
            </template>
          </template>
        </b-table>
      </filter-slot>
    </b-card>

    <modal-credit-card
      v-if="modalCreditCardState"
      :card="card"
      @hideModal="modalCreditCardState = false"
    />
    <modal-tracking
      v-if="modalTrackingState"
      :data-client="dataClient"
      @hideModal="modalTrackingState = false"
    />
    <payment-charge-modal
      v-if="paymentChargeOn"
      :data-charge="dataCharge"
      :title-add-charge="'ADD CHARGE'"
      @close="closePaymentChargeModal"
    />

    <modal-process-ncr
      v-if="modalProcess"
      :data-ncr="dataNcr"
      @refresh="refreshList"
      :typestatus="type_status"
      :statustk="status_tk"
      typecor="1"
      @close="closeModalProccessNcr"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import PaymentChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/PaymentChargeModal.vue";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients.vue";
import DataFields from "./fields.data";
import dataFilters from "./dataFilters.data";
import IconStatus from "./IconStatus.vue";
import NcrProgramsService from "../../../service/ncr-programs.service";
import CommunService from "../../../../letters/service/letters.service";
import ModalCreditCard from "../../modal/credit-card/ModalCreditCard.vue";
import ModalTracking from "../../modal/tracking/ModalTracking.vue";

import ModalProcessNcr from "@/views/commons/components/clients/dashboard/options/dis-module/modals/modal-process-ncr/ModalProcessNcr.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";

export default {
  components: {
    IconStatus,
    FilterSlot,
    ModalCreditCard,
    ModalTracking,
    PaymentChargeModal,
    BadgeRiskyClients,
    ModalProcessNcr,
    StatusAccount,
    NcrLeadsService,
    FilterStatusAccount,
  },
  data() {
    return {
      card: {},
      dataClient: {},
      modalCreditCardState: false,
      modalTrackingState: false,
      fields:
        this.moduleId != 5
          ? DataFields.NcrProgramsFields
          : DataFields.NcrProgramsFields.filter((col) => col.key !== "card_id"),
      filter: dataFilters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: 0,
      totalRows: 0,
      toPage: 0,
      sortBy: "",
      sortDesc: true,
      isBusy: false,
      total: 0,
      paymentChargeOn: false,
      dataCharge: {},
      dataNcr: null,
      modalProcess: false,
      status_tk: null,
      type_status: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_UPDATE_TABLE_NCR_PROGRAMS:
        "DebtSolutionNcrPrograms/G_UPDATE_TABLE_NCR_PROGRAMS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isDigitalOrConnection() {
      return this.$route.meta.is_digital === 2 || this.moduleId == 20;
    },
    filteredFields() {
      if (this.isDigitalOrConnection) {
        this.fields[3].label = "AGENT";
      }
      // this.fields[9].visible = this.moduleId === 22;

      if (this.moduleId == 20) this.fields[2].visible = false;

      if (this.$route.meta.status != null) {
        this.filter[3].visible = false;
      } else {
        this.filter[3].visible = true;
      }
      return this.fields.filter((item) => item.visible);
    },
    pathDashboard() {
      switch (this.moduleId) {
        case 5:
          return "debt-solution-dashboard";
          break;
        case 7:
          return "boost-credit-dashboard";
          break;
        case 22:
          return "ce-customer-service-dashboard";
          break;
        case 6:
          return "credit-experts-dashboard";
          break;
        case 20:
          return "connection-clients-account";
        case 11:
          return "specialists-client-dashboard";
          break;
      }
    },

    userAdvisor() {
      let advisor;
      switch (parseInt(this.currentUser.role_id)) {
        case 1: // CEO
          advisor = null;
          break;
        case 2: // Supervisor
          advisor = null;
          break;
        case 3: // Advisor
          if (this.moduleId === 7) {
            advisor = null;
          } else advisor = this.currentUser.user_id;
          break;
      }
      return advisor;
    },
    userAgent() {
      return parseInt(this.currentUser.role_id) == 15 ? true : false;
    },
  },
  watch: {
    G_UPDATE_TABLE_NCR_PROGRAMS(newVal) {
      if (newVal) {
        this.$refs.refNcrProgramstTable.refresh();
      }
    },
  },
  async created() {
    await this.getAdvisor();
    await this.getStatusNcr();
    this.filterHidden();
    this.getAllStatusClientAccount();
  },
  methods: {
    refreshList() {
      this.$refs.refNcrProgramstTable.refresh();
    },
    closeModalProccessNcr() {
      this.modalProcess = false;
    },
    openModalTracking(data) {
      this.modalTrackingState = true;
      this.dataClient = data;
    },
    modalCreditCard(data) {
      this.modalCreditCardState = true;
      this.card = data;
    },
    async getAdvisor() {
      if (!this.userAdvisor) {
        // CEO and Supervisor
        const result = await CommunService.getUsersAllPrograms({
          id: this.moduleId,
        });
        if (result) {
          result.unshift({
            id: 0,
            user_name: "All",
          });
          result.push({
            id: 1,
            user_name: "Roger Segura",
          });
        }
        const dataAdvisor = result.map((item) => ({
          id: item.id,
          label: item.user_name,
        }));
        if (this.moduleId === 7 && this.currentUser.role_id === 3) {
          const data = dataAdvisor.filter((item) => item.label === "All");
          this.filter[2].options = data;
        } else {
          this.filter[2].options = dataAdvisor;
        }
      } else {
        // Advisor
        this.filter[2].model = this.currentUser.user_id;
        this.filter[2].options.push({
          id: 0,
          label: "All",
        });
      }
    },
    async getStatusNcr() {
      const result = await NcrProgramsService.getStatusNcr();
      if (result) {
        if (this.$route.meta.status == null) {
          result.splice(2, 1);
        }

        const dataStatusNcr = result.map((item) => ({
          id: item.id,
          label: item.value,
        }));
        if (dataStatusNcr) {
          dataStatusNcr.unshift({
            id: "0",
            label: "All",
          });
        }
        this.filter[3].options = dataStatusNcr;
      }
    },

    async sentorevision(id, status_tk, status_id) {
      this.status_tk = status_tk;
      this.type_status = status_id;
      const result = await NcrProgramsService.processncrsearch({
        id: id,
      });
      if (result) {
        this.modalProcess = true;
        this.dataNcr = result[0];
      }
    },

    isAdvisorBk() {
      if (
        (this.moduleId === 7 && this.currentUser.role_id === 3) ||
        (this.moduleId === 22 && this.currentUser.role_id === 15) ||
        (this.moduleId === 20 && this.currentUser.role_id === 15)
      ) {
        return this.currentUser.user_id;
      }
      return this.filter[2].model;
    },
    paymentCharge(item) {
      this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
        id: item.id_account,
      });
      const data = {
        id: item.charge_id,
        fee: "NCR",
        amount: item.amount,
        t_charge: item.type_charge,
      };
      this.dataCharge = data;
      this.paymentChargeOn = true;
    },

    closePaymentChargeModal() {
      this.paymentChargeOn = false;
      this.$refs.refNcrProgramstTable.refresh();
    },
    async myProvider(ctx) {
      const lookupTable = {
        5: 4,
        6: 3,
        7: 2,
        20: 3,
        22: 3,
        11: 7,
      };
      const program_id = lookupTable[this.moduleId] || "";
      if (this.userAdvisor) {
        this.filter[2].model = this.currentUser.user_id;
        this.filter[2].options.push({
          id: 0,
          label: "All",
        });
      }
      let orderBy = 10;
      let sortDirection = "desc";
      if (ctx.sortBy === "status") {
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
        orderBy = 5;
      } else if (ctx.sortBy === "created_at") {
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
        orderBy = 10;
      }
      try {
        let params = {
          advisor: this.isAdvisorBk(),
          from: this.filter[0].model,
          name_text: this.filterPrincipal.model
            ? this.filterPrincipal.model
            : null,
          order: sortDirection,
          orderby: orderBy,
          paid: this.filter[4].model ? this.filter[4].model : null,
          program: program_id, // Programs varies by module
          status:
            this.$route.meta.status != null
              ? this.$route.meta.status
              : this.filter[3].model
              ? this.filter[3].model
              : null,
          statusclient: this.filter[5].model ? this.filter[5].model : null,
          to: this.filter[1].model,
          typencr: 1,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          module_id: this.moduleId,
          is_digital: this.$route.meta.is_digital
            ? this.$route.meta.is_digital
            : null,
        };
        this.getRolAgentConnection()
          ? (params.role_id = this.getRolAgentConnection())
          : "";

        const result = await NcrProgramsService.getNcrProgramas(params);
        this.startPage = result.from;
        this.toPage = result.to;
        this.totalRows = result.total;
        this.paginate.currentPage = result.current_page;
        this.paginate.perPage = result.per_page;
        if (result.data.length > 0) {
          result.data.map((dato) => {
            this.total = dato.sum_t;
          });
        } else {
          this.total = 0;
        }

        return result.data;
      } catch (error) {
        console.log("Somtehing went wrong myProvider", error);
      }
    },
    filterHidden() {
      if (this.userAgent) {
        this.filter[2].visible = false;
      } else {
        this.filter[2].visible = true;
      }
    },
    getRolAgentConnection() {
      if (this.moduleId == 20) {
        return this.currentUser.role_id == 15
          ? this.currentUser.role_id
          : false;
      } else {
        return false;
      }
    },
    async getAllStatusClientAccount() {
      this.filter[5].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filter[5].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filter[5].options.push(...data);
    },
  },
};
</script>
<style scoped>
.paddingL {
  padding-left: 23px;
}
</style>
