<template>
  <div>
    <div v-if="$route.meta.moduleId == 20" class="d-flex">
      <feather-icon
        v-if="statusAccount"
        :id="statusGlobal.state_hold == 2 ? 'triangle' : 'icon'"
        :icon="'CircleIcon'"
        size="14"
        :class="statusGlobal.state_hold == 2 ? '' : colorStatus"
      />
      <span>{{ statusClient }}</span>
    </div>
    <div v-else class="d-flex">
      <feather-icon
        v-if="statusAccount"
        id="icon"
        icon="CircleIcon"
        size="14"
        :class="colorStatus"
      />
      <span>{{ statusClient }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    statusAccount: {
      required: true,
      type: Number,
    },
    statusGlobal: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      status: "",
    };
  },
  computed: {
    colorStatus() {
      let selected;
      switch (this.statusAccount) {
        case 1:
          selected = "green marginTR";
          break;
        case 2:
          selected = "red marginTR";
          break;
        case 3:
          selected = "blue marginTR";
          break;
        case 4:
          selected = "red marginTR";
          break;
        case 5:
          selected = "red marginTR";
          break;
        case 6:
          selected = "red marginTR";
          break;
      }
      return selected;
    },
    statusClient() {
      if (this.statusAccount == 1) {
        this.status = "Active";
      } else if (this.statusAccount == 2) {
        this.status = "Hold";
      } else if (this.statusAccount == 3) {
        this.status = "Transition";
      } else if (this.statusAccount == 4) {
        this.status = "Canceled";
      } else if (this.statusAccount == 5) {
        this.status = "Loyal";
      } else if (this.statusAccount == 6) {
        this.status = "Closed";
      }
      return this.status;
    },
  },
};
</script>
<style scoped>
#icon {
  border-radius: 50%;
  border: none;
}
#triangle {
  width: 0px;
  height: 0px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 13px solid #ff0;
  margin-right: 6px;
  margin-top: 2px;
}
.marginTR {
  margin-top: 3px;
  margin-right: 5px;
}
.green {
  color: #00cc00;
  border-color: #00cc00;
  background: #00cc00;
}
.red {
  color: red;
  border-color: red;
  background: red;
}
.blue {
  color: #0066ff;
  border-color: #00cc00;
  background: #0066ff;
}

.yellow {
  color: #ff0;
  border-color: #ff0;
  background: #ff0;
}
</style>
