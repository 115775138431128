<template>
  <b-modal
    v-model="modalCenter"
    size="md"
    no-close-on-backdrop
    modal-class="modal-primary"
    title="Credit Card"
    title-tag="h3"
    hide-footer
    @hidden="hideModal"
  >
    <b-container class="mt-1">
      <b-row>
        <b-col cols="12" class="col-md-12 col-lg-3 col-xl-3">
          <b-form-group label="Card Holder Name">
            <b-form-input disabled v-model="cardData.cardholdername" trim />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="col-md-12 col-lg-9 col-xl-9">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="d-md-flex">
                <b-form-group label="Number Card">
                  <div class="d-flex justify-content-start">
                    <b-form-input
                      disabled
                      v-model="cardData.card1"
                      trim
                      class="widthN marginR"
                    />
                    <b-form-input
                      disabled
                      v-model="cardData.card2"
                      trim
                      class="widthN marginR"
                    />
                    <b-form-input
                      disabled
                      v-model="cardData.card3"
                      trim
                      class="widthN marginR"
                    />
                    <b-form-input
                      disabled
                      v-model="cardData.card4"
                      trim
                      class="widthN marginR"
                    />
                  </div>
                </b-form-group>

                <div class="d-sm-flex">
                  <b-form-group label="MM" label-for="mm" class="ml-1">
                    <b-form-input
                      disabled
                      id="mm"
                      name="mm"
                      trim
                      class="widthN marginR marginT"
                      v-model="cardData.card_expi_month"
                    />
                  </b-form-group>
                  <b-form-group label="YY" label-for="yy">
                    <b-form-input
                      disabled
                      id="yy"
                      name="yy"
                      trim
                      class="widthN marginR marginT"
                      v-model="cardData.card_expi_year"
                    />
                  </b-form-group>
                  <b-form-group label="CVC" label-for="cvc">
                    <b-form-input
                      disabled
                      id="cvc"
                      name="cvc"
                      trim
                      class="widthN marginT"
                      v-model="cardData.cardsecuritycode"
                    />
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Address" label-for="address" class="paddingT">
            <b-form-input
              disabled
              id="address"
              v-model="cardData.address"
              name="address"
              trim
              class="paddingT"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="City" label-for="city" class="paddingT">
            <b-form-input
              disabled
              id="city"
              name="city"
              trim
              class="paddingT"
              v-model="cardData.city"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="State" label-for="state" class="paddingT">
            <b-form-input
              disabled
              id="state"
              name="state"
              trim
              class="paddingT"
              v-model="cardData.states_eeuu_slug"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Zip code" label-for="zip" class="paddingT">
            <b-form-input
              disabled
              id="zip"
              name="zip"
              trim
              class="paddingT"
              v-model="cardData.zipcode"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Contry" label-for="contry" class="paddingT">
            <b-form-input
              disabled
              id="contry"
              name="contry"
              trim
              class="paddingT"
              v-model="cardData.country"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import NcrProgramsService from "../../../service/ncr-programs.service";
export default {
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      cardData: {
        cardholdername: "",
        card_expi_year: "",
        card_expi_month: "",
        cardsecuritycode: "",
        address: "",
        city: "",
        zipcode: "",
        states_eeuu_slug: "",
        country: "",
        card1: "",
        card2: "",
        card3: "",
        card4: "",
      },
      modalCenter: true,
    };
  },

  async created() {
    await this.getCardNcr();
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    hideModal() {
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    async getCardNcr() {
      this.addPreloader();
      try {
        let result = await NcrProgramsService.getCardNcr({
          card_id: this.card.card_id,
          modul: this.moduleId,
        });
        this.cardData = result[0];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped>
.widthN {
  width: 70px;
}
.marginR {
  margin-right: 10px;
}
.marginT {
  margin-top: 10px;
}
.paddingT {
  padding-top: 6px;
}
</style>
