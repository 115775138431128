<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    :title="title"
    size="lg"
    @hidden="closeModal"
  >
    <div>
      <b-row class="mb-1 mt-2">
        <!-- Name Client -->
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                class="title_lable d-flex justify-content-center"
                >CLIENT
              </b-input-group-text>
            </template>

            <span
              style="'border-color:rgba(255, 255, 255, 0.4)'; "
              :style="
                isDarkSkin
                  ? 'background: #17171A;opacity: 0.3;color:white'
                  : 'background: #efefef'
              "
              class="form-control"
              >{{ dataNcr.name }}</span
            >
          </b-input-group>
        </b-col>

        <!-- Amount -->
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                class="title_label d-flex justify-content-center"
                >AMOUNT
              </b-input-group-text>
            </template>

            <span
              style="'border-color:rgba(255, 255, 255, 0.4)'; "
              :style="
                isDarkSkin
                  ? 'background: #17171A;opacity: 0.3;color:white'
                  : 'background: #efefef'
              "
              class="form-control"
            >
              $ {{ dataNcr.amount }}</span
            >
          </b-input-group>
        </b-col>
      </b-row>

      <b-row v-if="typestatus == 5">
        <!-- Input: Date -->
        <b-col md="4">
          <b-form-group label="Date:" label-class="font-weight-bolder">
            <b-form-input
              v-if="moduleId == 4"
              v-model="dataNcr.date"
              class="font-weight-bolder"
              :disabled="true"
            />
            <b-form-datepicker
              v-else
              v-model="dataNcr.date"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :disabled="true"
              placeholder="mm/dd/yyyy"
              class="font-weight-bolder"
            />
          </b-form-group>
        </b-col>

        <!-- Select: Provider Ncr -->
        <b-col sm="4">
          <b-form-group label="Provider" label-class="font-weight-bolder">
            <v-select
              v-model="dataNcr.plataform_id"
              :options="providers"
              label="text"
              :reduce="(value) => value.id"
              :disabled="true"
              class="font-weight-bolder"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4"></b-col>

        <!-- Input: Equifax -->
        <b-col sm="4">
          <b-form-group label="Equifax" label-class="font-weight-bolder">
            <b-form-input
              v-model="dataNcr.equifax"
              class="font-weight-bolder"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <!-- Input: Experian -->
        <b-col sm="4">
          <b-form-group label="Experian" label-class="font-weight-bolder">
            <b-form-input
              v-model="dataNcr.experian"
              class="font-weight-bolder"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <!-- Input: Trans Union -->
        <b-col sm="4">
          <b-form-group label="Trans Union" label-class="font-weight-bolder">
            <b-form-input
              v-model="dataNcr.transunion"
              class="font-weight-bolder"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <validation-observer>
        <div class="col-lg-12 mt-1">
          <ValidationProvider
            v-slot="{ errors }"
            name="comment"
            rules="required"
          >
            <div class="form-group row">
              <span>Observation</span>

              <b-textarea
                v-model="obs"
                class="input-form"
                style="height: 140px"
                :class="{ 'border-danger': errors[0] }"
              />
            </div>
          </ValidationProvider>
        </div>
      </validation-observer>
    </div>

    <template #modal-footer>
      <!-- BUTTON SAVE -->
      <b-button
        v-if="statustk === 11"
        class="d-flex justify-content-center"
        variant="primary"
        @click="changeStatus"
      >
        <b-spinner v-if="spinnerOn" small />
        SAVE
      </b-button>

      <!-- BUTTON GUIVE BACK -->
      <b-button
        v-if="typestatus == 4"
        class="d-flex justify-content-center"
        variant="danger"
        @click="changeStatus"
      >
        <b-spinner v-if="spinnerOn" small />
        GIVE BACK
      </b-button>

      <!-- BUTTON PROCESS -->
      <b-button
        v-if="typestatus == 5 || typestatus == 6"
        class="d-flex justify-content-center"
        variant="success"
        @click="changeStatus"
      >
        <b-spinner v-if="spinnerOn" small />
        PROCESS
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";
import ClientOptionServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    dataNcr: null,
    typestatus: null,
    statustk: null,
    typecor: null,
  },
  data() {
    return {
      spinnerOn: false,
      obs: "",
      finishedLoading: false,
      ownControl: false,
      providers: [],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    title() {
      switch (this.statustk) {
        case 1:
          return "SEND";
        case 2:
          return "APPROVE";
        case 3:
          return "DISAPPROVE";
        case 4:
          return "REPLY";
        case 5:
          return "EDIT";
        case 6:
          return "CANCEL";
        case 7:
          return "REVISION";
        case 8:
          return "PROCESS";
        case 9:
          return "PENDING";
        case 10:
          return "CONFIRM";
        case 11:
          return "SEND";
        default:
          return "";
      }
    },
  },
  created() {
    this.ownControl = true;
    this.providers = [
      { id: 1, text: "IdentityIQ" },
      { id: 2, text: "MyFreeScoreNow" },
      { id: 3, text: "PrivacyGuard" },
    ];
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },

    async changeStatus() {
      let confirm = null;

      switch (this.typestatus) {
        case 9:
          /* Pending */

          break;

        case 5:
          confirm = await this.showConfirmSwal(
            "Are you sure?",
            "You won't be able to revert this!",
            {
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#ab9220",
              cancelButtonColor: "#8f9194",
              confirmButtonText: "Yes",
            }
          );

          if (confirm.isConfirmed) {
            try {
              const params = {
                id: this.dataNcr.id,
                type: this.typestatus,
                idsession: this.currentUser.user_id,
                statustk: this.statustk,
                obs: this.obs,
                typecor: this.typecor,
                elent: "",
                subject: "",
              };

              this.addPreloader();
              const data = await ClientOptionServices.changeStatusNcr(params);
              if (data.status == 200) {
                await Promise.all([
                  this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
                    idaccountclient: this.$route.params.idClient,
                  }),
                ]);
                this.$emit("refresh");
                this.removePreloader();
                this.closeModal();
              }
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } catch (error) {
              this.showToast(
                "danger",
                "top-right",
                "Oop!",
                "AlertOctagonIcon",
                this.getInternalErrors(error)
              );
            } finally {
              this.removePreloader();
            }
          }

          break;

        default:
          confirm = await this.showConfirmSwal(
            "Are you sure?",
            "You won't be able to revert this!",
            {
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#ab9220",
              cancelButtonColor: "#8f9194",
              confirmButtonText: "Yes",
            }
          );

          if (confirm.isConfirmed) {
            try {
              const params = {
                id: this.dataNcr.id,
                type: this.typestatus,
                idsession: this.currentUser.user_id,
                statustk: this.statustk,
                obs: this.obs,
                typecor: this.typecor,
                elent: "",
                subject: "",
              };
              this.addPreloader();
              const data = await ClientsOptionsServices.changeStatusNcr(params);
              if (data.status === 200) {
                await Promise.all([
                  this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
                    idaccountclient: this.$route.params.idClient,
                  }),
                ]);
                this.$emit("refresh");
                this.removePreloader();
                this.closeModal();
              }

              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } catch (error) {
              this.removePreloader();
              this.showToast(
                "danger",
                "top-right",
                "Oop!",
                "AlertOctagonIcon",
                this.getInternalErrors(error)
              );
            }
          }
          break;
      }
    },
  },
};
</script>

<style scoped>
</style>
