<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="modalCenter"
      centered
      modal
      size="md"
      header-class="p-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="TRACKING"
      title-class="h2 text-white"
      hide-footer
      @hidden="hideModal"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <ModalTrackingsHeader
          :dataClient="dataClient"
          @close="hideModal(false)"
        />
      </template>
      <b-container>
        <b-table
          slot="table"
          ref="refUserListTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="fields"
          :items="myProvider"
        >
          <!-- Loading -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column: DATE -->
          <template #cell(date)="data">
            <div>
              <p>{{ data.item.date | myGlobalDay }}</p>
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import ModalTrackingsHeader from "@/views/commons/components/ncr-programs/components/modal/tracking/ModalTrackingHeader.vue";
import NcrProgramsService from "../../../service/ncr-programs.service";
export default {
  components: {
    ModalTrackingsHeader,
  },
  props: {
    dataClient: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      items: [],
      fields: [
        {
          key: "date",
        },
        {
          key: "user_name",
          label: "User",
        },
        {
          key: "name_status",
          label: "Action",
        },
        {
          key: "status_process",
          label: "Status",
        },
        {
          key: "obs",
          label: "Observation",
        },
      ],
    };
  },
  methods: {
    hideModal() {
      this.$refs['myModal'].hide();
      this.modalCenter = false;
      this.$emit("hideModal", false);
    },
    async myProvider() {
      this.addPreloader();
      try {
        let result = await NcrProgramsService.getTrackingNcr({
          id: this.dataClient.nc_id,
        });
        return result;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
