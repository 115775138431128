export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Advisor",
    model: null,
    options: [],
    reduce: "id",
    seletText: "label",
    cols: 12,
    visible: true,
  },
  {
    type: "custom-select",
    margin: true,
    showLabel: true,
    label: "Client Status",
    model: null,
    options: [],
    reduce: "id",
    seletText: "label",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Paid",
    model: null,
    options: [
      { id: "0", label: "ALL" },
      { id: "1", label: "YES" },
      { id: "2", label: "NO" },
    ],
    reduce: "id",
    seletText: "label",
    cols: 4,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Client Status",
    model: null,
    options: [],
    reduce: "value",
    seletText: "label",
    cols: 8,
  },
];
